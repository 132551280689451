import React from 'react';
import loadable from "@loadable/component";
import { Routes, Route, Navigate } from "react-router-dom";

// Sidebar Menu
import { LoaderProvider } from '../../context/Preloader';
import PageLayout from '../../layouts/PageLayout';
import menu from './data/menu.json';
import Error from '../../layouts/Error404';
import Visits from '../general/pages/Visits/Index';
import Notifications from '../general/pages/Notifications/Index';
import Appointments from '../general/pages/Appointments/Index';
import { AdminFilter } from '../../filters/AdminFilter';
import Loader from '../../layouts/Loader';



const LoadablePage = loadable((props) => import(`./pages/${props.page}`), {
    fallback: <Loader />,
    cacheKey: (props) => props.page
});

const LoadDynamic = loadable((props) => import(`../${props.page}`), {
    fallback: <Loader />,
    cacheKey: (props) => props.page
});

const AdminRouter = () => {
    return (
        <React.Fragment>
            <AdminFilter >
                <PageLayout menu={menu}>
                    <Routes>
                        <Route index element={<LoadablePage page={'Dashboard'} />} />
                        <Route path="dashboard" element={<LoadablePage page="Dashboard" />} />
                        <Route path="patients">
                            <Route index element={<LoadablePage page="Patients/Index" />} />
                            <Route path=":patientId" element={<LoadablePage page="Patients/Profile" />} />
                            <Route path="create" element={<LoadablePage page="Patients/Create" />} />
                        </Route>
                        <Route path="staff">
                            <Route index element={<LoadablePage page="Staff/Index" />} />
                            <Route path=":staffId" element={<LoadablePage page="Staff/Profile" />} />
                            <Route path="create" element={<LoadablePage page="Staff/Create" />} />
                        </Route>
                        <Route path="settings" element={<LoadablePage page="Settings/Index" />} />

                        <Route path="departments">
                            <Route index element={<LoadablePage page="Departments/Index" />} />
                            <Route path=":departmentId" element={<LoadablePage page="Departments/Profile" />} />
                        </Route>

                        <Route path='visits' >
                            <Route index element={<LoadDynamic page="general/pages/Visits/Index" />} />
                            <Route path=':visitId' element={<LoadDynamic page="general/pages/Visits/Profile" />} />
                        </Route>
                        <Route path="notifications" element={<Notifications />} />
                        <Route path="appointments" element={<Appointments />} />

                        <Route path="*" element={<Error />} />
                    </Routes>
                </PageLayout>
            </AdminFilter>
        </React.Fragment>
    )
}

export default AdminRouter;