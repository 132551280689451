import React, { useContext, useEffect } from "react";
import { List, Item, Anchor, Button } from "../elements";
import { DrawerContext } from "../../context/Drawer";
import { useLocation } from "react-router-dom";

export default function MenuItem({ item }) {
    const mediaQuery = window.matchMedia("(max-width: 991px)");
    const [active, setActive] = React.useState(false);
    const { drawer, toggleDrawer } = useContext(DrawerContext);
    const location = useLocation();

    useEffect(() => {
        if (!item.submenu) {
            if (location.pathname === item.href) setActive(true)
            else setActive(false)
        }
    }, [location.pathname, active, item.href]);
    return (
        <Item className={`mc-sidebar-menu-item ${active ? "active" : ""}`}
            onClick={item.submenu ? () => setActive(!active) : (mediaQuery.matches ? () => toggleDrawer() : null)}>
            {item.submenu ?
                <>
                    <Button
                        icon={item.icon}
                        text={item.text}
                        badge={item.badge}
                        arrow="expand_more"
                        className="mc-sidebar-menu-btn"
                    />
                    <List className="mc-sidebar-dropdown-list">
                        {item.submenu.map((item, index) => (
                            <Item key={index} className="mc-sidebar-dropdown-item" onClick={mediaQuery.matches ? () => toggleDrawer() : null}>
                                <Anchor href={item.href} className="mc-sidebar-dropdown-link">
                                    {item.text}
                                </Anchor>
                            </Item>
                        ))}
                    </List>
                </>
                :
                <Anchor
                    href={item.href}
                    icon={item.icon}
                    text={item.text}
                    badge={item.badge}
                    className="mc-sidebar-menu-btn"
                />
            }
        </Item>
    )
}