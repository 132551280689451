import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/Authentication'

export const AdminFilter = ({ children }) => {
    const auth = useAuth();

    if (auth.staff) {
        if (!auth.staff.roles.includes('admin')) {
            return <Navigate to={'/general'} />
        }
    }

    return children
}