import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Result } from "antd";
import { Col, Row } from "react-bootstrap";

export default function Error() {
    const navigate = useNavigate();
    return (
        <>
            <Row>
                <Col md={12}>
                    <Card>
                        <Result
                            status="404"
                            title="ooops! this page can't be found."
                            subTitle="It's looks like nothing was found at this location."
                            extra={<Button type="primary" onClick={() => navigate(-1)}>Back to Previous Page</Button>}
                        />
                    </Card>
                </Col>
            </Row>
        </>
    )
}