import { ThemeProvider } from "./context/Themes";
import { LoaderProvider } from "./context/Preloader";
import { Routes, Route, HashRouter, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Overview, Documentation, ChangeLog, Error } from "./pages/supports";

// ToDo: ================= Module Routers =================
import AdminRouter from './modules/admin/index';
import MedicalRouter from './modules/medical/index';
import AccountRouter from './modules/account/index';
import LaboratoryRouter from './modules/laboratory/index';
import PharmacyRouter from './modules/pharmacy/index';
import GeneralRouter from "./modules/general/index";
import { AuthProvider } from "./context/Authentication";
import Login from "./modules/general/pages/auth/Login";
import { AuthFilter } from "./filters/AuthFilter";
import Error from "./layouts/Error404";
import Logout from "./modules/general/pages/auth/Logout";
import loadable from "@loadable/component";
import Visits from "./modules/general/pages/Visits/Index";
import PageLayout from "./layouts/PageLayout";
import menu from "./modules/general/data/menu.json";


export default function App() {
    return (
        <ThemeProvider>
            <LoaderProvider>
                <HashRouter>
                    <AuthProvider>
                        <Routes>

                            {/* =========== Module Routes ============ */}
                            <Route path='/administration/*' element={<AuthFilter ><AdminRouter /> </AuthFilter>} />
                            <Route path='/medical/*' element={<AuthFilter ><MedicalRouter /> </AuthFilter>} />
                            <Route path='/account/*' element={<AuthFilter ><AccountRouter /> </AuthFilter>} />
                            <Route path='/laboratory/*' element={<AuthFilter ><LaboratoryRouter /> </AuthFilter>} />
                            <Route path='/pharmacy/*' element={<AuthFilter ><PharmacyRouter /> </AuthFilter>} />


                            {/* ============ General Pages ================ */}
                            <Route path='/' element={<Navigate to={'/general'} />} />
                            <Route path='/general/*' element={<AuthFilter ><GeneralRouter /></AuthFilter>} />


                            {/* ============ Public Pages ================= */}
                            <Route path='/login' element={<Login />} />
                            <Route path='/logout' element={<Logout />} />
                            <Route path='*' element={<Error />} />

                        </Routes>
                        <ToastContainer />
                    </AuthProvider>
                </HashRouter>
            </LoaderProvider>
        </ThemeProvider>
    );
}

