import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

export function FlashMessage({ message, variant }) {
    const [show, setShow] = useState(true);

    if (message && show) {
        return (
            <>
                <Alert variant={variant || 'info'} onClose={() => setShow(false)} dismissible>
                    <p>
                        {message}
                    </p>
                </Alert>
            </>
        );
    }
}

