import { useAuth } from '../../../../context/Authentication'
import { Navigate } from 'react-router-dom'

const Logout = () => {
    const auth = useAuth()
    auth.logout();

    return <Navigate to={'/login'} />
}

export default Logout