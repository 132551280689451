import { Spin } from 'antd'
import React from 'react'

const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: '#fff',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 9999,
}

const Loader = () => {
    return (
        <div>
            <div style={containerStyle}>
                <Spin size="large" />
            </div>
        </div>
    )
}

export default Loader