import { async } from 'q';
import { useState, useContext, createContext } from 'react';
import { Navigate } from 'react-router';
import { toast } from 'react-toastify';
import fetchApi from '../database/axios';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const data = {}
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [staff, setStaff] = useState();

    const login = async ({ token, staff }) => {
        localStorage.setItem("token", token);
        setToken(token);
        setStaff(staff);
    }

    const logout = () => {
        localStorage.setItem("token", '');
        setToken(null);
        setStaff(null);
    }

    const fetchStaff = () => {
        fetchApi('get', '/auth/me').then(res => {
            setStaff(res.data.data);
        }).catch(err => {
            if (err.response) {
                toast.error(err.response.data.error);
            }
            logout();
        });
    }


    return <AuthContext.Provider value={{ token, staff, data, login, logout, fetchStaff }}>{children}</AuthContext.Provider>
}


export const useAuth = () => {
    return useContext(AuthContext);
}